import ChairishABTest from 'chairisher/abtest';

/**
 * AB Test for determining if we show the variant mulberry modal
 **
 * TODO: (CHAIR-17877) Delete file after test is done
 */
export default class MulberryModalABCTest extends ChairishABTest {
    static getExperimentName() {
        return 'MULBERRY_MODAL_ABC_TEST';
    }
}
