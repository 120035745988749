import $ from 'jquery';

import getRewardsRedemptionUrl from 'chairisher/context/reward';

import { logAmplitudeClickEvent, logAmplitudeEvent } from 'chairisher/util/analytics';

/**
 * Tracks link clicks in the dashboard
 *
 * @param {Event} e
 * @param {string} programName
 */
export function trackRewardsDashboardLinkClick(e, programName) {
    const $link = $(e.currentTarget);

    logAmplitudeClickEvent($link.text().trim(), `${programName}s dashboard`, $link.attr('href') || window.location);
}

/**
 * Tracks clicks on the REDEEM button in the dashboard
 *
 * @param {Event} e
 * @param {string} programName
 */
export function trackRewardsRedemptionLinkClick(e, programName) {
    const $button = $(e.currentTarget);
    logAmplitudeClickEvent($button.text().trim(), `${programName}s dashboard`, getRewardsRedemptionUrl());
}

/**
 * Tracks a successful reward redemption
 *
 * @param {string} programName
 */
export function trackRewardsRedemptionSuccess(programName) {
    logAmplitudeEvent(`${programName}s - redemption success`);
}

/**
 * Tracks an error when attempting to redeem rewards
 *
 * @param {string} programName
 */
export function trackRewardsRedemptionError(programName) {
    logAmplitudeEvent(`${programName}s - redemption error`);
}
